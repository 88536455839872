import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const SEOMetaTags = ({ title, description }) =>
{
   const { lang } = useParams();
   const baseUrl = process.env.REACT_APP_BASE_URL || 'https://rivalapps.com';
   const currentPath = window.location.pathname;
   const pathWithoutLang = currentPath.replace(`/${lang}`, '');

   return (
      <Helmet>
         <title>{title}</title>
         <meta name="description" content={description} />

         {/* Language alternates */}
         <link rel="alternate" hrefLang="en" href={`${baseUrl}/en${pathWithoutLang}`} />
         <link rel="alternate" hrefLang="ru" href={`${baseUrl}/ru${pathWithoutLang}`} />
         <link rel="alternate" hrefLang="x-default" href={`${baseUrl}/en${pathWithoutLang}`} />

         {/* Open Graph tags */}
         <meta property="og:title" content={title} />
         <meta property="og:description" content={description} />
         <meta property="og:url" content={`${baseUrl}${currentPath}`} />
         <meta property="og:type" content="website" />

         {/* Twitter Card tags */}
         <meta name="twitter:card" content="summary" />
         <meta name="twitter:title" content={title} />
         <meta name="twitter:description" content={description} />
      </Helmet>
   );
};

export default SEOMetaTags; 