import React from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AccountDeletion = () =>
{
   const { t } = useTranslation();

   return (
      <Container maxWidth="lg">
         <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom>
               {t('accountDeletion.title')}
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
               <Typography variant="h6" gutterBottom>
                  {t('accountDeletion.request.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('accountDeletion.request.content')}
               </Typography>

               <Typography variant="h6" gutterBottom>
                  {t('accountDeletion.consequences.title')}
               </Typography>
               <Typography variant="body1" component="div" sx={{ mb: 3 }}>
                  <ul>
                     {t('accountDeletion.consequences.items', { returnObjects: true }).map((item, index) => (
                        <li key={index}>{item}</li>
                     ))}
                  </ul>
               </Typography>

               <Typography variant="body1" paragraph>
                  {t('accountDeletion.process.content')}
               </Typography>

               <Button
                  variant="contained"
                  color="error"
                  href="mailto:support@rivalapps.com?subject=Account%20Deletion%20Request"
                  sx={{ mt: 2 }}
               >
                  {t('accountDeletion.process.button')}
               </Button>
            </Paper>
         </Box>
      </Container>
   );
};

export default AccountDeletion; 