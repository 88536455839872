import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import enTranslations from './locales/en/translations.json';
import ruTranslations from './locales/ru/translations.json';

// Get the initial language from localStorage or browser settings
const getInitialLanguage = () =>
{
   // First check localStorage
   const savedLang = localStorage.getItem('i18nextLng');
   if (savedLang && ['en', 'ru'].includes(savedLang))
   {
      return savedLang;
   }

   // Then check browser language
   const browserLang = navigator.language.split('-')[0];
   if (['en', 'ru'].includes(browserLang))
   {
      return browserLang;
   }

   // Default to English if no match
   return 'ru';
};

i18n
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      resources: {
         en: {
            translation: enTranslations,
         },
         ru: {
            translation: ruTranslations,
         },
      },
      lng: getInitialLanguage(), // Set initial language
      fallbackLng: 'ru',
      debug: process.env.NODE_ENV === 'development',

      interpolation: {
         escapeValue: false,
      },

      detection: {
         order: ['localStorage', 'navigator'],
         caches: ['localStorage'],
         lookupLocalStorage: 'i18nextLng',
      },
   });

export default i18n; 