import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate, useParams } from 'react-router-dom';

const LanguageSwitcher = () =>
{
   const { t, i18n } = useTranslation();
   const navigate = useNavigate();
   const { lang } = useParams();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) =>
   {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () =>
   {
      setAnchorEl(null);
   };

   const changeLanguage = (newLang) =>
   {
      // Change the language in i18next
      i18n.changeLanguage(newLang);

      // Get the current path without the language prefix
      const currentPath = window.location.pathname;
      const pathWithoutLang = currentPath.replace(`/${lang}`, '');

      // Navigate to the new language path
      navigate(`/${newLang}${pathWithoutLang || '/home'}`);

      // Close the menu
      handleClose();
   };

   return (
      <>
         <Button
            id="language-button"
            aria-controls={open ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<LanguageIcon />}
            sx={{ color: 'white' }}
         >
            {t(`language.${i18n.language}`)}
         </Button>
         <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               'aria-labelledby': 'language-button',
            }}
         >
            <MenuItem onClick={() => changeLanguage('en')}>{t('language.en')}</MenuItem>
            <MenuItem onClick={() => changeLanguage('ru')}>{t('language.ru')}</MenuItem>
         </Menu>
      </>
   );
};

export default LanguageSwitcher; 