import React from 'react';
import { Container, Typography, Box, Paper, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Terms = () =>
{
   const { t } = useTranslation();

   return (
      <Container maxWidth="lg">
         <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom>
               {t('terms.title')}
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
               <Typography variant="body1" paragraph>
                  {t('terms.intro')}
               </Typography>

               <Typography variant="h6" gutterBottom>
                  {t('terms.acceptance.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.acceptance.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.license.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.license.content')}
               </Typography>
               <List>
                  {t('terms.license.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.accounts.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.accounts.content')}
               </Typography>
               <List>
                  {t('terms.accounts.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.subscriptions.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.subscriptions.content')}
               </Typography>
               <List>
                  {t('terms.subscriptions.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.payments.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.payments.content')}
               </Typography>
               <List>
                  {t('terms.payments.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.userContent.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.userContent.content')}
               </Typography>
               <List>
                  {t('terms.userContent.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.prohibited.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.prohibited.content')}
               </Typography>
               <List>
                  {t('terms.prohibited.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.termination.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.termination.content')}
               </Typography>
               <List>
                  {t('terms.termination.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.disclaimer.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.disclaimer.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.limitation.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.limitation.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.governing.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.governing.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.changes.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.changes.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('terms.contact.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.contact.content')}
               </Typography>
               <Typography variant="body1">
                  {t('terms.contact.email')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('terms.contact.address')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                  {t('terms.effectiveDate')}
               </Typography>
            </Paper>
         </Box>
      </Container>
   );
};

export default Terms; 