import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Footer = ({ lang }) =>
{
   const { t } = useTranslation();

   return (
      <Box
         component="footer"
         sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) => theme.palette.grey[900],
            color: 'white',
         }}
      >
         <Container maxWidth="lg">
            <Grid container spacing={4}>
               <Grid item xs={12} sm={4}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                     {t('footer.company.name')}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                     {t('footer.company.description')}
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                     {t('footer.mainLinks')}
                  </Typography>
                  <Link component={RouterLink} to={`/${lang}/home`} color="inherit" display="block" sx={{ mb: 1 }}>
                     {t('nav.home')}
                  </Link>
                  <Link component={RouterLink} to={`/${lang}/contacts`} color="inherit" display="block" sx={{ mb: 1 }}>
                     {t('nav.contacts')}
                  </Link>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Typography variant="h6" color="inherit" gutterBottom>
                     {t('footer.legal')}
                  </Typography>
                  <Link component={RouterLink} to={`/${lang}/terms`} color="inherit" display="block" sx={{ mb: 1 }}>
                     {t('footer.terms')}
                  </Link>
                  <Link component={RouterLink} to={`/${lang}/privacy`} color="inherit" display="block" sx={{ mb: 1 }}>
                     {t('footer.privacy')}
                  </Link>
                  <Link component={RouterLink} to={`/${lang}/account-deletion`} color="inherit" display="block" sx={{ mb: 1 }}>
                     {t('footer.accountDeletion')}
                  </Link>
               </Grid>
            </Grid>
            <Typography variant="body2" color="inherit" align="center" sx={{ mt: 4 }}>
               © {t('footer.copyright', { year: new Date().getFullYear() })} RivalApps. All rights reserved.
            </Typography>
         </Container>
      </Box>
   );
};

export default Footer; 