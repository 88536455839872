import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import enTranslations from './locales/en/translations.json';
import ruTranslations from './locales/ru/translations.json';

i18n
   // Detect user language
   .use(LanguageDetector)
   // Pass the i18n instance to react-i18next
   .use(initReactI18next)
   // Initialize i18next
   .init({
      resources: {
         en: {
            translation: enTranslations,
         },
         ru: {
            translation: ruTranslations,
         },
      },
      fallbackLng: 'ru',
      debug: process.env.NODE_ENV === 'development',

      interpolation: {
         escapeValue: false, // React already escapes values
      },

      detection: {
         order: ['navigator', 'htmlTag', 'path', 'subdomain'],
         caches: ['localStorage'],
      },
   });

export default i18n; 