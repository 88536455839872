import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contacts from './pages/Contacts';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import AccountDeletion from './pages/AccountDeletion';
import './i18n'; // Import i18n configuration

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
  },
});

// Language redirect component with proper path handling
const LanguageRedirect = () =>
{
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;

  // If we're already on a language path, don't redirect
  if (currentPath.match(/^\/(en|ru)\//))
  {
    return null;
  }

  // Get the path without any language prefix
  const pathWithoutLang = currentPath.replace(/^\/(en|ru)/, '');

  // Redirect to the current language path
  return <Navigate to={`/${i18n.language}${pathWithoutLang || '/home'}`} replace />;
};

function App()
{
  const { i18n } = useTranslation();

  // Effect to handle initial language setup
  useEffect(() =>
  {
    // If we're on the root path, redirect to the current language
    if (window.location.pathname === '/')
    {
      window.location.href = `/${i18n.language}/home`;
    }
  }, [i18n.language]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              maxWidth: '100vw'
            }}
          >
            <Navbar />
            <Box component="main" sx={{ flexGrow: 1, mt: 5 }}>
              <Routes>
                <Route path="/" element={<LanguageRedirect />} />
                <Route path="/:lang" element={<Home />} />
                <Route path="/:lang/home" element={<Home />} />
                <Route path="/:lang/contacts" element={<Contacts />} />
                <Route path="/:lang/terms" element={<Terms />} />
                <Route path="/:lang/privacy" element={<Privacy />} />
                <Route path="/:lang/account-deletion" element={<AccountDeletion />} />
                <Route path="*" element={<Navigate to={`/${i18n.language}/home`} replace />} />
              </Routes>
            </Box>
            <Footer lang={i18n.language} />
          </Box>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
