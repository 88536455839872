import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Card, Paper, useMediaQuery, useTheme, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import '../App.css';

const Home = () =>
{
   const { t } = useTranslation();
   const [scrollProgress, setScrollProgress] = useState(0);
   const [numLines, setNumLines] = useState(50); // Number of lines
   const [lineProperties, setLineProperties] = useState([]);
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const [features, setFeatures] = useState([
      {
         title: t('home.features.orderManagement.title'),
         description: t('home.features.orderManagement.description'),
         image: 'screen1.png'
      },
      {
         title: t('home.features.chats.title'),
         description: t('home.features.chats.description'),
         image: 'screen2.png'
      },
      {
         title: t('home.features.chatbot.title'),
         description: t('home.features.chatbot.description'),
         image: 'screen3.png'
      },
      {
         title: t('home.features.maps.title'),
         description: t('home.features.maps.description'),
         image: 'screen1.png'
      },
      {
         title: t('home.features.inventory.title'),
         description: t('home.features.inventory.description'),
         image: 'screen3.png'
      },
      {
         title: t('home.features.reports.title'),
         description: t('home.features.reports.description'),
         image: 'screen2.png'
      }
   ]);

   const getRandomHeight = () =>
   {
      return Math.floor(Math.random() * 100) + 40; // Random number between 4 and 25
   };

   // Function to get random width offset
   const getRandomWidthOffset = () =>
   {
      return Math.random() * 40 - 5; // Random number between -5 and 5
   };

   // Function to get random color class
   const getRandomColorClass = (index) =>
   {
      const oddColors = ['color1', 'color2', 'color3'];
      const evenColors = ['color4', 'color5', 'color6'];
      const colors = index % 2 === 0 ? evenColors : oddColors;
      return colors[Math.floor(Math.random() * colors.length)];
   };

   // Initialize line properties once when component mounts
   useEffect(() =>
   {
      const height = Math.floor(document.documentElement.clientHeight / 70);
      console.log("[+] height: ", height);
      setNumLines(height);

      // Generate properties for each line
      const properties = Array(height).fill().map((_, index) => ({
         height: getRandomHeight(),
         widthOffset: getRandomWidthOffset(),
         colorClass: getRandomColorClass(index)
      }));

      setLineProperties(properties);
   }, []);

   useEffect(() =>
   {
      const handleScroll = () =>
      {
         const scrollTop = window.scrollY;
         const docHeight = document.documentElement.scrollHeight - window.innerHeight;
         const scrollPercent = docHeight > 0 ? scrollTop / docHeight : 0;
         setScrollProgress(scrollPercent);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   return (
      <Container maxWidth="lg">
         <Helmet>
            <title>RivalApps - Home</title>
            <meta name="description" content="RivalApps - Instagram order management and other apps for your business" />
            <meta name="keywords" content="mobile app, application, rivalapps, technology, instagram, instagram order management, instagram order management app, order management, order management app, order management system" />
            <meta property="og:title" content="RivalApps - Instagram order management app for iOS and Android" />
            <meta property="og:description" content="RivalApps - Instagram order management and other apps for your business" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://rivalapps.com" />
            <meta property="og:image" content="https://rivalapps.com/og-image.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="RivalApps - Instagram order management app for iOS and Android" />
            <meta name="twitter:description" content="RivalApps - Instagram order management and other apps for your business" />
            <meta name="twitter:image" content="https://rivalapps.com/twitter-image.jpg" />
            <link rel="canonical" href="https://rivalapps.com" />
         </Helmet>
         <Box sx={{ mb: 4, position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
            <Typography variant="h2" component="div" sx={{ textAlign: 'center', m: 0 }}>
               {t('home.title')}
            </Typography>
            <Divider sx={{ width: '80%', m: 0 }} />
            {features.map((feature, index) => (
               <Box key={`feature-${index}`} className='paper-container'>
                  {isMobile ? (
                     // Mobile layout: title on top, image in middle, description at bottom
                     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 1, maxWidth: '90vw' }}>
                        <Typography variant="h4" component="div" sx={{ textAlign: 'center', mb: 2 }}>
                           {feature.title}
                        </Typography>
                        <Box
                           sx={{
                              maxHeight: '50vh',
                              padding: 1,
                              mb: 2,
                              borderRadius: 1, borderWidth: '1px', borderColor: 'black',
                              textAlign: 'center'
                           }}
                        >
                           <img
                              src={`./screenshot/${feature.image}`}
                              alt="Screenshot of the app"
                              style={{ width: 'auto', height: 'auto', maxHeight: '50vh', maxWidth: '90vw' }}
                           />
                        </Box>
                        <Typography variant="body1" sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                           {feature.description}
                        </Typography>
                        <Divider sx={{ width: '80vw', m: 0 }} />
                     </Box>
                  ) : (
                     <Box sx={{
                        display: 'flex',
                        flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                        textAlign: index % 2 === 0 ? 'right' : 'left',
                        alignItems: 'center',
                        gap: 2,
                        p: 3
                     }}>
                        <Box
                           sx={{
                              flex: 0.5,
                              padding: 1,
                              borderRadius: '10px',
                              maxHeight: '500px',
                              ...(index % 2 === 0 ? { mr: 2 } : { ml: 2 })
                           }}
                        >
                           <img
                              src={`./screenshot/${feature.image}`}
                              alt="Screenshot of the app"
                              style={{ width: 'auto', height: 'auto', maxHeight: '500px' }}
                           />
                        </Box>
                        <Box sx={{ flex: 0.5, textAlign: index % 2 === 0 ? 'left' : 'right' }}>
                           <Typography variant="h3" component="div" sx={{ mb: 2 }}>
                              {feature.title}
                           </Typography>
                           <Typography variant="body1" component="div">
                              {feature.description}
                           </Typography>
                        </Box>
                     </Box>
                  )}
               </Box>
            ))}
         </Box>
         <div className='scrolling-lines-container'>
            {[...Array(numLines)].map((_, index) => (
               <div
                  key={index}
                  className={`lines ${index % 2 === 0 ? "reverse" : ""} ${lineProperties[index]?.colorClass || 'color1'}`}
                  style={{
                     top: `${5 + index * 15}%`,
                     height: `${lineProperties[index]?.height || 15}px`,
                     width: index % 2 === 0 ?
                        `calc(100% + ${lineProperties[index]?.widthOffset || 0}%)` :
                        `calc(100% + ${lineProperties[index]?.widthOffset || 0}%)`,
                     transform: index % 2 === 0 ? `translateX(${-(scrollProgress - 0.5) * 150}%)` : `translateX(${(scrollProgress - 0.6) * 150}%)`,
                  }}
               ></div>
            ))}
         </div>
      </Container>
   );
};

export default Home; 