import React from 'react';
import { Container, Typography, Box, Paper, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Privacy = () =>
{
   const { t } = useTranslation();

   return (
      <Container maxWidth="lg">
         <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom>
               {t('privacy.title')}
            </Typography>

            <Paper sx={{ p: 3, mt: 2 }}>
               <Typography variant="body1" paragraph>
                  {t('privacy.intro')}
               </Typography>

               <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                  {t('privacy.collect.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.collect.content')}
               </Typography>
               <List>
                  {t('privacy.collect.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.use.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.use.content')}
               </Typography>
               <List>
                  {t('privacy.use.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.sharing.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.sharing.content')}
               </Typography>
               <List>
                  {t('privacy.sharing.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.security.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.security.content')}
               </Typography>
               <List>
                  {t('privacy.security.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.dataRetention.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.dataRetention.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.yourRights.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.yourRights.content')}
               </Typography>
               <List>
                  {t('privacy.yourRights.items', { returnObjects: true }).map((item, index) => (
                     <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemText primary={item} />
                     </ListItem>
                  ))}
               </List>
               <Typography variant="body1" paragraph>
                  {t('privacy.yourRights.contact')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.children.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.children.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.changes.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.changes.content')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="h6" gutterBottom>
                  {t('privacy.contact.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.contact.content')}
               </Typography>
               <Typography variant="body1">
                  {t('privacy.contact.email')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.contact.address')}
               </Typography>

               <Divider sx={{ my: 2 }} />

               <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                  {t('privacy.effectiveDate')}
               </Typography>
            </Paper>
         </Box>
      </Container>
   );
};

export default Privacy; 