import React, { useState } from 'react';
import
{
   AppBar,
   Toolbar,
   Typography,
   IconButton,
   Drawer,
   List,
   ListItem,
   ListItemText,
   Box,
   useTheme,
   useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const Navbar = () =>
{
   const [mobileOpen, setMobileOpen] = useState(false);
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   const { t } = useTranslation();
   const { lang } = useParams();

   const handleDrawerToggle = () =>
   {
      setMobileOpen(!mobileOpen);
   };

   const menuItems = [
      { text: t('nav.home'), path: `/${lang}/home` },
      { text: t('nav.contacts'), path: `/${lang}/contacts` },
   ];

   const drawer = (
      <List>
         {menuItems.map((item) => (
            <ListItem
               button
               component={Link}
               to={item.path}
               key={item.text}
               onClick={handleDrawerToggle}
            >
               <ListItemText primary={item.text} />
            </ListItem>
         ))}
      </List>
   );

   return (
      <>
         <AppBar position="sticky" sx={{ backgroundColor: '#1F334F', height: 90 }}>
            <Toolbar sx={{ height: 90, justifyContent: 'center' }}>
               {isMobile && (
                  <IconButton
                     color="inherit"
                     aria-label="open drawer"
                     edge="start"
                     onClick={handleDrawerToggle}
                     sx={{ mr: 2 }}
                  >
                     <MenuIcon />
                  </IconButton>
               )}
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Box
                     component="img"
                     sx={{
                        height: 60,
                        width: 60,
                        mr: 2,
                        backgroundColor: 'white',
                        borderRadius: 1,
                     }}
                     src='../logo512.png'
                     alt="RivalApps Logo"
                  />
                  <Typography variant="h5" noWrap component="div">
                     RivalApps
                  </Typography>
               </Box>
               <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: 'center' }}>
                  {!isMobile && (
                     <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                        {menuItems.map((item) => (
                           <Typography
                              key={item.text}
                              component={Link}
                              to={item.path}
                              sx={{
                                 color: 'white',
                                 textDecoration: 'none',
                                 fontSize: 18,
                                 '&:hover': {
                                    textDecoration: 'underline',
                                 },
                              }}
                           >
                              {item.text}
                           </Typography>
                        ))}
                     </Box>
                  )}
               </Box>
               <LanguageSwitcher />
            </Toolbar>
         </AppBar>
         <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
               keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
               display: { xs: 'block', sm: 'none' },
               '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
            }}
         >
            {drawer}
         </Drawer>
      </>
   );
};

export default Navbar; 