import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Privacy = () =>
{
   const { t } = useTranslation();

   return (
      <Container maxWidth="lg">
         <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom>
               {t('privacy.title')}
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
               <Typography variant="h6" gutterBottom>
                  {t('privacy.collect.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.collect.content')}
               </Typography>

               <Typography variant="h6" gutterBottom>
                  {t('privacy.use.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.use.content')}
               </Typography>

               <Typography variant="h6" gutterBottom>
                  {t('privacy.sharing.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.sharing.content')}
               </Typography>

               <Typography variant="h6" gutterBottom>
                  {t('privacy.security.title')}
               </Typography>
               <Typography variant="body1" paragraph>
                  {t('privacy.security.content')}
               </Typography>
            </Paper>
         </Box>
      </Container>
   );
};

export default Privacy; 