import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSwitcher = () =>
{
   const { t, i18n } = useTranslation();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) =>
   {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () =>
   {
      setAnchorEl(null);
   };

   const changeLanguage = (lng) =>
   {
      i18n.changeLanguage(lng);
      handleClose();
   };

   return (
      <>
         <Button
            id="language-button"
            aria-controls={open ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<LanguageIcon />}
            sx={{ color: 'white' }}
         >
            {t(`language.${i18n.language}`)}
         </Button>
         <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               'aria-labelledby': 'language-button',
            }}
         >
            <MenuItem onClick={() => changeLanguage('en')}>{t('language.en')}</MenuItem>
            <MenuItem onClick={() => changeLanguage('ru')}>{t('language.ru')}</MenuItem>
         </Menu>
      </>
   );
};

export default LanguageSwitcher; 