import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';

const Contacts = () =>
{
   const { t } = useTranslation();

   return (
      <Container maxWidth="lg">
         <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom>
               {t('contacts.title')}
            </Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
               <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 3, height: '100%' }}>
                     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <EmailIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">{t('contacts.email')}</Typography>
                     </Box>
                     <Typography variant="body1">
                        {t('contacts.emailValue')}
                     </Typography>
                  </Paper>
               </Grid>
               <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 3, height: '100%' }}>
                     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <PhoneIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">{t('contacts.phone')}</Typography>
                     </Box>
                     <Typography variant="body1">
                        {t('contacts.phoneValue')}
                     </Typography>
                  </Paper>
               </Grid>
               <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 3, height: '100%' }}>
                     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <LocationOnIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">{t('contacts.address')}</Typography>
                     </Box>
                     <Typography variant="body1">
                        {t('contacts.addressValue')}
                     </Typography>
                  </Paper>
               </Grid>
            </Grid>
         </Box>
      </Container>
   );
};

export default Contacts; 